'use client';

import styled from 'styled-components';
import { Section } from '@/app/(components)/SectionWrapper';
import { H2, LargeBodyText } from '@/lib/StyledComponents/Typography';
import { Button } from '@/app/(components)/shared/Button';
import { useTranslation } from 'react-i18next';
import Spacer from '@/app/(components)/shared/Spaces';
import { LinkButton } from '@/app/(components)/CmsBlocks/LinkButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export function ErrorSection({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Section fullWidth>
      <Container>
        <H2>{t('Something went wrong!')}</H2>
        <LargeBodyText>
          {t('Error message:')} {error?.message}
        </LargeBodyText>
        <Spacer height={2} />
        <ButtonContainer>
          {reset && <Button onClick={() => reset()}>{t('Try again')}</Button>}
          <LinkButton href={'/'}>{t('Home')}</LinkButton>
        </ButtonContainer>
      </Container>
    </Section>
  );
}
