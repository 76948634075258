import(/* webpackMode: "eager", webpackExports: ["ErrorSection"] */ "/src/app/(components)/ErrorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuickLinks"] */ "/src/app/(components)/Footer/components/QuickLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/src/app/(components)/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/src/app/(components)/Navbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/lib/datadog/datadog-browser-logs-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/lib/datadog/datadog-rum-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/lib/i18n/I18next-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStyle"] */ "/src/lib/StyledComponents/GlobalStyle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/lib/StyledComponents/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/lib/StyledComponents/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/src/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
