'use client';

import { mq } from '@/lib/StyledComponents/mq';
import { H2 } from '@/lib/StyledComponents/Typography';
import Image from 'next/image';
import styled from 'styled-components';
import facebookLogo from '/public/facebook.svg';
import linkedInLogo from '/public/linkedin.svg';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${mq(null, 'laptop')} {
    gap: 0;
  }
`;

const AddressContainer = styled.div`
  ${mq(null, 'laptop')} {
    margin: 1.625rem 0 3.75rem 0;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.625rem;

  ${mq(null, 'laptop')} {
    justify-content: center;
  }
`;

type Props = {
  phoneNumber: string;
  emailAddress: string;
  address: {
    name: string;
    street: string;
    postalCodeAndCity: string;
  };
};

export const ContactInfo = ({ emailAddress, phoneNumber, address }: Props) => {
  return (
    <Container>
      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      <AddressContainer>
        <H2>{address.name}</H2>
        <H2>{address.street}</H2>
        <H2>{address.postalCodeAndCity}</H2>
      </AddressContainer>
      <IconsContainer>
        <a href="https://www.linkedin.com/company/kvd-bilpriser/">
          <Image
            src={linkedInLogo}
            alt="linkedIn-logo"
            height={30}
            width={30}
          ></Image>
        </a>
        <a href="https://www.facebook.com/bilpriser.se/">
          <Image
            src={facebookLogo}
            alt="facebook-logo"
            height={30}
            width={30}
          ></Image>
        </a>
      </IconsContainer>
    </Container>
  );
};
