'use client';

import { css, styled } from 'styled-components';
import {
  type Colors,
  type TextBaseStyleProps,
  type TextColor,
} from '@/lib/StyledComponents/styled';
import { ButtonText } from '@/lib/StyledComponents/Typography';

export type ButtonBaseProps = {
  $backgroundColor?: Colors;
  $textColor?: TextColor;
  $fullWidth?: boolean;
  $borderColor?: Colors;
} & TextBaseStyleProps;

export type ButtonOutlinedProps = {
  $variant: 'outline';
} & ButtonBaseProps;

export type ButtonFullProps = {
  $variant?: 'full';
} & ButtonBaseProps;

export type ButtonProps = ButtonOutlinedProps | ButtonFullProps;

export const ButtonStyle = css<ButtonProps>`
  width: ${({ $fullWidth: fullWidth }) => (fullWidth ? '100%' : 'initial')};
  margin: 0;
  padding: clamp(0.75rem, 2vw, 1.125rem) clamp(1.5rem, 2vw, 1.875rem);

  border-radius: ${({ theme }) => `${theme.buttonRadius}rem`};

  color: ${({ theme, $textColor: textColor }) =>
    theme.colors.text[textColor ?? 'dark']};
  background-color: ${({
    theme,
    $variant = 'full',
    $backgroundColor: backgroundColor,
  }) =>
    $variant == 'full'
      ? theme.colors.main[backgroundColor ?? 'primary']
      : 'inherent'};
  border: ${({ theme, $borderColor, $backgroundColor }) =>
    `0.125rem solid ${theme.colors.main[$borderColor ?? $backgroundColor ?? 'primary']}`};

  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;

  @media (hover: hover) {
    &:hover {
      ${({
        theme,
        $variant = 'full',
        $backgroundColor,
        $borderColor,
        $textColor,
      }) =>
        $variant == 'full'
          ? css`
              color: ${theme.colors.main[$backgroundColor ?? 'primary']};
              background-color: ${$textColor
                ? theme.colors.text[$textColor]
                : theme.colors.background.dark1};
              border-color: ${theme.colors.main[
                $borderColor ?? $backgroundColor ?? 'primary'
              ]};
            `
          : css`
              color: ${theme.colors.text['light']};
              background-color: ${theme.colors.text[$textColor ?? 'light']};
            `}
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  ${ButtonText}
`;

export const Button = styled.button<ButtonProps>`
  ${ButtonStyle}
`;
