interface MqSizes {
  mobileS: "320px";
  mobileM: "375px";
  mobileL: "425px";
  tablet: "768px";
  laptop: "1024px";
  laptopL: "1440px";
  laptopXL: "1680px";
  desktop: "2560px";
}
const mqSizes: MqSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  laptopXL: "1680px",
  desktop: "2560px",
};

const mqNumberSizes: Record<SCREEN_SIZE, number> = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  laptopXL: 1680,
  desktop: 2560,
};

const mqSizesOrder: { [key: string]: number } = {
  mobileS: 1,
  mobileM: 2,
  mobileL: 3,
  tablet: 4,
  laptop: 5,
  laptopL: 6,
  laptopXL: 7,
  desktop: 8,
};

export type SCREEN_SIZE = keyof typeof mqSizes;

const mediaQueries = {
  mobileS: (limit: string) => `(${limit}-width: ${mqSizes.mobileS})`,
  mobileM: (limit: string) => `(${limit}-width: ${mqSizes.mobileM})`,
  mobileL: (limit: string) => `(${limit}-width: ${mqSizes.mobileL})`,
  tablet: (limit: string) => `(${limit}-width: ${mqSizes.tablet})`,
  laptop: (limit: string) => `(${limit}-width: ${mqSizes.laptop})`,
  laptopL: (limit: string) => `(${limit}-width: ${mqSizes.laptopL})`,
  desktop: (limit: string) => `(${limit}-width: ${mqSizes.desktop})`,
  desktopL: (limit: string) => `(${limit}-width: ${mqSizes.desktop})`,
};

const mq = (from: SCREEN_SIZE | null, to?: SCREEN_SIZE) => {
  if (from && to) {
    return `@media (min-width: ${mqNumberSizes[from]}px) and (max-width: ${
      mqNumberSizes[to] - 1
    }px)`;
  }

  if (from) {
    return `@media (min-width: ${mqNumberSizes[from]}px)`;
  }

  if (to) {
    return `@media (max-width: ${mqNumberSizes[to] - 1}px)`;
  }

  console.error("Invalid use of mq. Available breakpoints are:", mqSizes);
  return "@media screen";
};

export { mq, mqSizes, mqNumberSizes, mqSizesOrder, mediaQueries };
