'use client';

import styled from 'styled-components';
import Image from 'next/image';
import hamburger from '/public/hamburger.svg';
import { type NavigationModel } from '@/schema/cms/models/NavigationModel.schema';
import { useEffect, useState } from 'react';
import { LinkItem } from './components/LinkItem';
import { mq } from '@/lib/StyledComponents/mq';
import { FullScreenMenu } from './components/FullScreenMenu';
import BilpriserLogo from '../icons/BilpriserLogo';
import type { LinkButtonBlockData } from '@/schema/cms/blocks/LinkButtonBlock';
import Link from 'next/link';
import { LinkButton } from '../CmsBlocks/LinkButton';
import { InfoBanner } from '../InfoBanner';

const Container = styled.div<{ $scrolled: boolean }>`
  position: sticky;
  top: -1px;
  background-color: ${({ theme, $scrolled }) =>
    $scrolled ? '#1b202feb' : theme.colors.background.dark1};
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  transition: padding 0.2s;
  padding: ${({ $scrolled }) => ($scrolled ? '1rem' : '1.375rem')} 1.875rem;

  ${mq(null, 'laptop')} {
    background-color: transparent;
    background-color: ${({ theme, $scrolled }) =>
      $scrolled ? theme.colors.background.dark1 : 'transparent'};
    padding: 1rem;
    transition: none;
  }
`;

const Content = styled.nav`
  width: 100%;
  height: 100%;
  max-width: 1440px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ImageContainerType = {
  $scrolled: boolean;
  $showFullScreenMenu: boolean;
};

const ImageContainer = styled.div<ImageContainerType>`
  flex: 1;

  a {
    display: block;
    width: min-content;
  }

  svg {
    width: auto;
    min-width: 4.688rem; //75px
    transition:
      height 0.2s,
      fill 0.4s;
    height: ${({ $scrolled }) => ($scrolled ? '1.25rem' : '1.875rem')};
    fill: ${({ $showFullScreenMenu, theme }) =>
      $showFullScreenMenu ? theme.colors.text.dark : theme.colors.text.light};
  }

  ${mq(null, 'laptop')} {
    svg {
      position: ${({ $showFullScreenMenu }) =>
        $showFullScreenMenu ? 'fixed' : 'static'};
      top: calc(2rem);
      width: auto;
      height: 1.125rem;
      z-index: 10;
    }
  }
`;

const Links = styled.ul`
  display: none;

  ${mq('laptop')} {
    display: flex;
    justify-content: center;
    gap: 3.5rem;
    flex: 2;
  }

  li {
    position: relative;
  }

  a {
    font-weight: ${({ theme }) => theme.fontWeights.black};
    transition: color 0.2s ease;
    text-transform: uppercase;
    font-size: 0.875rem;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.main.primary};
  }

  li:hover > ul {
    display: flex;
  }
`;

const ButtonContainer = styled.div`
  flex: auto;
  display: flex;
  justify-content: flex-end;
  gap: 1.875rem;

  ${mq('laptop')} {
    flex: 1;
  }
`;

const StyledMenuButton = styled.button`
  display: block;

  ${mq('laptop')} {
    display: none;
  }
`;

type Props = {
  menuLinks: NavigationModel['menuLinks'];
  button: LinkButtonBlockData;
  infoBanner: NavigationModel['infoBanner'];
  showInfoBanner: boolean;
};

export const Navbar = ({
  menuLinks,
  button,
  infoBanner,
  showInfoBanner,
}: Props) => {
  const [scrolled, setScrolled] = useState(false);
  const [showFullScreenMenu, setShowFullScreenMenu] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  return (
    <>
      {showInfoBanner && <InfoBanner text={infoBanner} />}
      <Container $scrolled={scrolled}>
        <Content>
          <ImageContainer
            $scrolled={scrolled}
            $showFullScreenMenu={showFullScreenMenu}
          >
            <Link href="/">
              <BilpriserLogo />
            </Link>
          </ImageContainer>
          <Links>
            {menuLinks &&
              menuLinks.map(link => <LinkItem key={link.url} data={link} />)}
          </Links>
          <ButtonContainer>
            <LinkButton
              href={button.url}
              $textColor={button.textColorNew}
              $backgroundColor={button.backgroundColorNew}
              $variant={button.variant}
            >
              {button.label}
            </LinkButton>
            <StyledMenuButton
              onClick={() => {
                setShowFullScreenMenu(true);
              }}
            >
              <Image
                src={hamburger}
                alt="hamburger-meny"
                height={25}
                width={25}
              ></Image>
            </StyledMenuButton>
          </ButtonContainer>
        </Content>
        {showFullScreenMenu && (
          <FullScreenMenu
            data={menuLinks}
            handleClose={() => {
              setShowFullScreenMenu(false);
            }}
          />
        )}
      </Container>
    </>
  );
};
