'use client';

import { css, styled } from 'styled-components';
import { type TextBaseStyleProps } from './styled';

export const TextBaseStyle = css<TextBaseStyleProps>`
  font-family: var(--font-inter);
  font-style: normal;
  line-height: normal;

  ${({ $textColor: color }) =>
    Boolean(color) &&
    typeof color !== 'undefined' &&
    css`
      color: ${({ theme }) => theme.colors.text[color]};
    `};

  ${({ $align }) =>
    Boolean($align) &&
    typeof $align !== 'undefined' &&
    css`
      text-align: ${$align};
    `};

  ${({ $uppercase: uppercase }) =>
    Boolean(uppercase) &&
    css`
      text-transform: uppercase;
    `}

  white-space: pre-line;
`;

export const H1 = styled.h1<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 2.25rem;
  font-weight: 900;

  margin: 0;
  margin-bottom: 1.5rem;
`;

export const H2 = styled.h2<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 1.5rem;
  font-weight: 900;

  margin: 0;
  margin-bottom: 1.5rem;
`;

export const H3 = styled.h3<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 1.125rem;
  font-weight: 900;

  margin: 0;
  margin-bottom: 1.5rem;
`;

export const H4 = styled.h4<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 0.875rem;
  font-weight: 900;
  letter-spacing: 0.08rem;

  margin: 0;
  margin-bottom: 1.5rem;
`;

export const LargeBodyText = styled.p<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 1rem;
  letter-spacing: 0.08rem;
  font-weight: ${({ theme, $fontWeight: fontWeight }) =>
    fontWeight ? theme.fontWeights[fontWeight] : theme.fontWeights.normal};
`;

export const BodyText = styled.p<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: ${({ theme, $fontWeight: fontWeight }) =>
    fontWeight ? theme.fontWeights[fontWeight] : theme.fontWeights.normal};
`;

export const ButtonText = css<TextBaseStyleProps>`
  ${TextBaseStyle}
  font-size: clamp(0.75rem,2vw,0.875rem);
  font-weight: ${({ theme, $fontWeight }) =>
    $fontWeight ? theme.fontWeights[$fontWeight] : theme.fontWeights.black};
  line-height: 1.125rem; /* 150% */
  letter-spacing: 0.06rem;
  text-transform: ${({ $uppercase = true }) =>
    $uppercase ? 'uppercase' : 'none'};
`;

export const InputText = css<TextBaseStyleProps>`
  ${BodyText}
  font-size: clamp(0.75rem,2vw,0.875rem);
  line-height: 1.125rem; /* 150% */
`;

export const InputPlaceholderText = css<TextBaseStyleProps>`
  ${TextBaseStyle}
  text-transform: none;
  font-weight: 400;
  line-height: 1.5rem;
  font-style: italic;
  font-size: clamp(0.875rem, 2vw, 1rem);
`;
