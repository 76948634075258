// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogRum as rum } from '@datadog/browser-rum';
import { useEffect } from 'react';

let datadogRum: typeof rum | null = null;

export default function DatadogRumInit({
  applicationId,
  clientToken,
  service,
  env,
}: {
  applicationId?: string;
  clientToken?: string;
  service?: string;
  env?: string;
}) {
  useEffect(() => {
    if (!applicationId || !clientToken || !service || !env) {
      return;
    }

    if (datadogRum) {
      return;
    }

    rum.init({
      applicationId,
      clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service,
      env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum = rum;
  }, [applicationId, clientToken, service, env]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
