'use client';

import { mq } from '@/lib/StyledComponents/mq';
import styled from 'styled-components';

export interface SpacerProps {
  height?: number;
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
}

const Spacer = styled.div<SpacerProps>`
  width: 100%;
  height: ${props => props.height ?? 1}rem;

  ${mq(null, 'tablet')} {
    ${props => props.hideOnMobile && `display: none;`}
  }

  ${mq(null, 'laptop')} {
    ${props => props.hideOnDesktop && `display: none;`}
  }
`;

export default Spacer;
