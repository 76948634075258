'use client';

import styled from 'styled-components';
import BilpriserLogo from '../icons/BilpriserLogo';
import { BodyText, H2, H4 } from '@/lib/StyledComponents/Typography';
import { type TextLinkBlockData } from '@/schema/cms/blocks/TextLinkBlock.schema';
import { LinkItem } from '../Navbar/components/LinkItem';
import { mq } from '@/lib/StyledComponents/mq';
import { ContactInfo } from './components/ContactInfo';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.primary};
  color: ${({ theme }) => theme.colors.text.dark};
  padding: 6.25rem 2.5rem;
  width: 100%;

  display: flex;
  justify-content: center;

  ${mq(null, 'laptop')} {
    padding: 3.5rem 2.5rem;
  }
`;

const Container = styled.div`
  max-width: 1440px;

  svg {
    height: 3.5rem;
    width: auto;
  }

  a {
    font-size: 1.5rem;
    font-weight: 900;
    text-decoration: underline;
    transition: color 0.2s ease;
  }
  a:hover {
    color: ${({ theme }) => theme.colors.main.info};
  }

  h2 {
    font-size: 1.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin: 0;
  }

  ${mq(null, 'laptop')} {
    flex-direction: column;

    a {
      text-decoration: none;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  margin: 1.125rem 0 3.75rem 0;
  gap: 2.5rem;

  ${mq(null, 'laptop')} {
    flex-direction: column;
    margin-bottom: 1.25rem;
  }
`;

const GeneralInfo = styled.div`
  flex: 1;

  p {
    margin-top: 0.375rem;
    margin-bottom: 1.25rem;
  }

  h4 {
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  li {
    list-style: none;
  }

  a {
    font-size: 1rem;
    text-decoration-color: ${({ theme }) => theme.colors.main.info};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  ${mq(null, 'laptop')} {
    h4 {
      margin-top: 2.5rem;
      margin-bottom: 0.625rem;
    }
  }
`;

const Links = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  ${mq(null, 'laptop')} {
    gap: 0.625rem;
  }
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  opacity: 0.6;

  a {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.main.info};
  }

  ${mq(null, 'laptop')} {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

type Props = {
  contactInfo: {
    phoneNumber: string;
    emailAddress: string;
    address: {
      name: string;
      street: string;
      postalCodeAndCity: string;
    };
  };
  links: TextLinkBlockData[];
  ourMission: string;
  slogan: string;
  kvdGroup: string;
  kvdGroupLinks: TextLinkBlockData[];
  copyright: string;
  rightsReserved: string;
  websitePolicy: string;
};

export const Footer = ({
  contactInfo,
  links,
  ourMission,
  slogan,
  kvdGroup,
  kvdGroupLinks,
  copyright,
  rightsReserved,
  websitePolicy,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Container>
        <BilpriserLogo />
        <ContentContainer>
          <GeneralInfo>
            <BodyText>{ourMission}</BodyText>
            <H2>{slogan}</H2>
            <H4>{kvdGroup}</H4>
            {kvdGroupLinks.map((link, index) => (
              <LinkItem
                target="_blank"
                key={`${link.url}-${index}`}
                data={link}
              />
            ))}
          </GeneralInfo>
          <Links>
            {links &&
              links.map((link, index) => (
                <LinkItem key={`${link.url}-${index}`} data={link} />
              ))}
          </Links>
          <ContactInfo {...contactInfo} />
        </ContentContainer>
        <Copyright>
          <p>{copyright}</p>
          <p>{rightsReserved}</p>
          <a href={`/${t('ROUTES.WEBSITE_POLICY')}`}>
            <p>{websitePolicy}</p>
          </a>
        </Copyright>
      </Container>
    </FooterContainer>
  );
};
