'use client';

import styled, { css } from 'styled-components';

type SectionContainerProps = {
  $light?: boolean;
};

export const SectionContainer = styled.section<SectionContainerProps>`
  padding: 0;
  position: relative;
  background-size: cover;

  background-color: ${({ theme, $light }) =>
    $light ? '#ececec' : theme.colors.background.dark1};
`;

type SectionWrapperProps = {
  $fullWidth?: boolean;
};

export const SectionWrapper = styled.div<SectionWrapperProps>`
  position: relative;
  width: 100%;

  ${({ $fullWidth }) =>
    !$fullWidth &&
    css`
      padding-left: 1.88rem;
      padding-right: 1.88rem;
      max-width: 90rem;
      margin-left: auto;
      margin-right: auto;
    `}
`;

type SectionProps = {
  children: React.ReactNode;
  light?: boolean;
  fullWidth?: boolean;
};

export const Section = ({ children, light, fullWidth }: SectionProps) => {
  return (
    <SectionContainer $light={Boolean(light)}>
      <SectionWrapper $fullWidth={Boolean(fullWidth)}>
        {children}
      </SectionWrapper>
    </SectionContainer>
  );
};
