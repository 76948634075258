'use client';

import { createGlobalStyle } from 'styled-components';
import { BodyText } from './Typography';

export const GlobalStyle = createGlobalStyle`
  body {
	background: ${({ theme }) => {
    return theme?.colors?.background?.dark2;
  }};
	color: ${({ theme }) => theme?.colors?.text?.light};
  	${BodyText}
  }
`;
