import i18n from 'i18next';

import lngSV from './translations/sv.json' with { type: 'json' };
import lngEN from './translations/en.json' with { type: 'json' };
import lngNO from './translations/no.json' with { type: 'json' };

export const supportedLng = {
  en: 'English',
  no: 'Norwegian',
  sv: 'Swedish',
};

export const config = locale => ({
  ns: ['default'],
  defaultNS: 'default',
  lng: locale,
  fallbackLng: 'en',
  supportedLngs: Object.keys(supportedLng),
  debug: false,
  interpolation: { escapeValue: false },
  preload: Object.keys(supportedLng),
});

export function init(locale) {
  i18n.init({
    ...config(locale),
    resources: {
      sv: { default: lngSV },
      en: { default: lngEN },
      no: { default: lngNO },
    },
  });

  return i18n;
}
