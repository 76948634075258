import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import { config } from './i18n-config.mjs';

import lngSV from './translations/sv.json';
import lngEN from './translations/en.json';
import lngNO from './translations/no.json';

export function init(locale: string) {
  i18n.use(initReactI18next).init({
    ...config(locale),
    resources: {
      sv: {
        default: lngSV,
      },
      en: {
        default: lngEN,
      },
      no: {
        default: lngNO,
      },
    },
  });

  return i18n;
}
