'use client';

import { init } from './i18n-react-config';
import { type PropsWithChildren } from 'react';
import { I18nextProvider as Provider } from 'react-i18next';

type I18NextProviderProps = PropsWithChildren<{
  locale?: string;
}>;

export default function I18NextProvider({
  children,
  locale,
}: I18NextProviderProps) {
  return <Provider i18n={init(locale ?? 'en')}>{children}</Provider>;
}
