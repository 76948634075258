export const theme = {
  cardRadius: 0.625,
  buttonRadius: 6.25,
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  colors: {
    main: {
      primary: '#38E58B',
      secondary: '#01A2FF',
      tertiary: '#8D5BDF',
      info: '#48a0f8',
      warning: '#F4DE4B',
      error: '#EE5B5A',
      gray: '#D5D5D5',
      dark: '#1B202F',
    },
    light: {
      primary: '#82EFAC',
      secondary: '#A9EBFA',
      tertiary: '#BDAAD3',
      info: '#A9EBFA',
      warning: '#F8EC9E',
      error: '#FFB8A4',
      gray: '#ECECEC',
      dark: '#1B202F',
    },
    text: {
      dark: '#1B202F', // "#1A1F30",
      neutral: '#7D7D86',
      light: '#FFFFFF',
      primary: '#38E58B',
      linkHeading: '#395049',
    },
    background: {
      dark1: '#1B202F', //"#1A1F30",
      dark2: '#333442',
      neutral: '#F4F4F5',
      light: '#ffffff',
      tertiaryDark: '#6B50AD',
    },
  },
};
