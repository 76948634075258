'use client';

import { H4, LargeBodyText } from '@/lib/StyledComponents/Typography';
import type { NavigationModel } from '@/schema/cms/models/NavigationModel.schema';
import styled from 'styled-components';
import arrow from '/public/arrow.svg';
import Image from 'next/image';
import Link from 'next/link';
import { mq } from '@/lib/StyledComponents/mq';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.light.primary};
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.dark};
  padding: 3.75rem 2.5rem;

  ${mq('laptop')} {
    padding: 7rem 2.5rem 7rem;
  }
`;

const Links = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  ${mq('laptop')} {
    flex-direction: row;
    justify-content: space-between;
    gap: calc(1rem * 8);
  }
`;

const StyledLink = styled(Link)`
  flex: 1 1 33%;

  h4 {
    color: ${({ theme }) => theme.colors.text.linkHeading};
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0.625rem;
  }

  &:hover {
    img {
      left: 1rem;
      transition: left 0.2s ease-in-out;
    }
  }

  ${mq('laptop')} {
    h4 {
      font-size: 1rem;
    }
  }
`;

const StyledLargeBodyText = styled(LargeBodyText)`
  display: inline;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  img {
    position: relative;
    display: inline-block;
    left: 0.5rem;
    transition: left 0.2s ease-in-out;
  }

  ${mq('laptop')} {
    font-size: 1.5rem;
  }
`;

export const QuickLinks = ({
  data,
}: {
  data: NavigationModel['quickLinks'];
}) => {
  return (
    <Container>
      <Links>
        {data.map(link => {
          return (
            <StyledLink key={link.title} href={link.url}>
              <H4>{link.title}</H4>
              <StyledLargeBodyText>
                {link.text}
                <Image src={arrow} alt="arrow-icon" width={32} height={23} />
              </StyledLargeBodyText>
            </StyledLink>
          );
        })}
      </Links>
    </Container>
  );
};
