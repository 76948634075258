'use client';

import { BodyText } from '@/lib/StyledComponents/Typography';
import type { NavigationModel } from '@/schema/cms/models/NavigationModel.schema';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.tertiaryDark};
  text-align: center;
  padding: 1rem;
`;

export const InfoBanner = ({
  text,
}: {
  text: NavigationModel['infoBanner'];
}) => {
  return (
    <Container>
      <BodyText>{text}</BodyText>
    </Container>
  );
};
