// Necessary if using App Router to ensure this file runs on the client
'use client';

import type { Env } from '@/app/env';
import { datadogLogs as logs } from '@datadog/browser-logs';
import { useEffect } from 'react';

let datadogLogs: typeof logs | null = null;

export default function DatadogBrowserLogsInit({
  clientToken,
  site,
  service,
  env,
  version,
}: {
  clientToken?: string;
  site?: Env['DD_SITE'];
  service?: string;
  env?: string;
  version?: string;
}) {
  useEffect(() => {
    if (!clientToken || !site) {
      return;
    }

    if (datadogLogs) {
      return;
    }

    logs.init({
      clientToken: clientToken,
      site: site,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service,
      env,
      version,
    });

    datadogLogs = logs;
  }, [clientToken, site, service, env, version]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
