'use client';

import Image from 'next/image';
import styled from 'styled-components';
import { Button } from '../../shared/Button';
import close from '/public/close.svg';
import { LinkItem } from './LinkItem';
import { type TextLinkBlockData } from '@/schema/cms/blocks/TextLinkBlock.schema';
import { mq } from '@/lib/StyledComponents/mq';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('laptop')} {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.main.primary};
`;

const TopContainer = styled.div`
  width: 100%;
  height: 2rem;
  padding: 2.48rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > button > img {
    width: 2rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 2.5rem;
`;

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    padding-bottom: 2rem;
  }

  a {
    font-weight: ${({ theme }) => theme.fontWeights.black};
    color: ${({ theme }) => theme.colors.text.dark};
    transition: color 0.2s ease;
    font-size: 1.5rem;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.main.info};
    line-height: 1.1;
  }
`;

export const FullScreenMenu = ({
  data,
  handleClose,
}: {
  data: TextLinkBlockData[];
  handleClose: () => void;
}) => {
  return (
    <Container>
      <TopContainer>
        <button onClick={handleClose}>
          <Image src={close} alt="close" height={32} width={32}></Image>
        </button>
      </TopContainer>
      <ContentContainer>
        <Links>
          {data &&
            data.map(link => (
              <LinkItem key={link.url} data={link} handleClick={handleClose} />
            ))}
        </Links>
        <Button
          $backgroundColor="dark"
          $textColor="primary"
          onClick={handleClose}
        >
          Verdsett bilen din
        </Button>
      </ContentContainer>
    </Container>
  );
};
