'use client';

import { theme } from '@/lib/StyledComponents/theme';
import { type TextLinkBlockData } from '@/schema/cms/blocks/TextLinkBlock.schema';
import styled from 'styled-components';
import Link from 'next/link';

const SubLinksContainer = styled.ul`
  display: none;
  position: absolute;
  flex-direction: column;
  gap: 0.875rem;
  left: -1rem;
  background-color: #1b202feb;
  padding: 0.5rem 1rem;
`;

const SubLinkItem = styled.li<{ $hoverColor: string }>`
  white-space: nowrap;

  a {
    display: flex;
    align-items: center;
  }

  a::before {
    content: '';
    background-color: ${({ $hoverColor }) => $hoverColor};
    height: 0.625rem;
    min-width: 0.625rem;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  a:hover {
    color: ${({ $hoverColor }) => $hoverColor} !important;
  }
`;

export const LinkItem = ({
  data,
  handleClick,
  target = '_self',
}: {
  data: TextLinkBlockData;
  handleClick?: () => void;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
}) => {
  const { text, url, subLinks } = data;
  const showSubLinks = subLinks && subLinks?.length > 0;

  return (
    <li onClick={handleClick}>
      <Link target={target} href={url}>
        {text}
      </Link>
      {showSubLinks && (
        <SubLinksContainer>
          {subLinks?.map(subLink => (
            <SubLinkItem
              key={subLink.text}
              $hoverColor={subLink.hoverColor ?? theme.colors.text.light}
            >
              <Link href={subLink.url}>{subLink.text}</Link>
            </SubLinkItem>
          ))}
        </SubLinksContainer>
      )}
    </li>
  );
};
